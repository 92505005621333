import { Link } from 'gatsby'
import React, { useState, useEffect } from 'react'
import { Component } from 'react'

import Contact from '../contact/contact'

import logo from '../../images/StellarLogoWhite.svg'

import headerStyles from './header.module.css'

import anime from 'animejs/lib/anime.es.js'

export default class Header extends Component {
  constructor (props) {
    super(props)
    this.state = {
      mobileNavbar: false,
      isTop: false
    }

    this.mobileNavbar = React.createRef()
  }

  componentDidUpdate () {
    if (this.state.mobileNavbar) {
      document.querySelector('body').classList.add('overflow-y-hidden')
      anime({
        targets: this.mobileNavbar.current,
        left: 0,
        duration: 600,
        opacity: 1,
        easing: 'easeOutCirc'
      })
    } else if (!this.state.mobileNavbar) {
      document.querySelector('body').classList.remove('overflow-y-hidden')

      anime({
        targets: this.mobileNavbar.current,
        left: '-100vw',
        duration: 600,
        opacity: 0,
        easing: 'easeOutCirc'
      })
    }
  }

  componentDidMount () {
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY > 0
      if (isTop !== this.state.isTop) {
        this.setState({ isTop })
      }
    })
  }

  resetOverflow () {
    document.querySelector('body').classList.remove('overflow-y-hidden')
  }

  render () {
    return (
      <header
        className={`${headerStyles.header} ${
          this.state.isTop
            ? `bg-gray-800 py-3 ${headerStyles.activeNavbar}`
            : ''
        }`}>
        <div className={`container ${headerStyles.container}`}>
          <Link to='/' className='flex items-center lg:-ml-10'>
            <img src={logo} draggable={false} className={headerStyles.logo} />
            <h2 className={headerStyles.logoText}>StellarDev</h2>
          </Link>
          
          <button
            className={`${headerStyles.mobileBtn} lg:hidden`}
            onClick={() => {
              this.setState({ mobileNavbar: !this.state.mobileNavbar })
            }}>
            <span className={headerStyles.spanLine}>&nbsp;</span>
            <span className={`${headerStyles.spanLine} my-1`}>&nbsp;</span>
            <span className={headerStyles.spanLine}>&nbsp;</span>
          </button>
        </div>
      </header>
    )
  }
}
