import React from 'react'

import { Link } from 'gatsby'

import buttonStyles from './button.module.css'

export default function button ({
  type,
  loc,
  btnType,
  children,
  className,
  target
}) {
  if (type === 'int') {
    return (
      <a
        href={loc}
        className={`${buttonStyles.btn} ${buttonStyles[btnType]} ${className}`}
        target={target ? '_blank' : ''}>
        {children}
      </a>
    )
  }

  if (type === 'ext') {
    return (
      <Link
        to={loc}
        className={`${buttonStyles.btn}  ${
          buttonStyles[btnType]
        } ${className}`}>
        {children}
      </Link>
    )
  }
}
