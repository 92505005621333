import React from 'react'
import logo from '../../images/StellarLogoWhite.svg'

import { Link } from 'gatsby'

import Contact from '../contact/contact'

export default function footer () {
  return (
    <footer className='flex flex-col items-center justify-center pt-10 pb-5 container'>
      <img src={logo} alt={'Stellar logo'} draggable={false} className='w-16' />
      <p className='text-lg font-bold my-3'>StellarDev</p>
      <ul className='flex items-center justify-between w-7/12 text-sm md:w-40'>
        <li>
          <Link
            to='/'
            className='opacity-75
 hover:opacity-100'>
            Home
          </Link>
        </li>
        <li>
          <Link
            to='/plugins'
            className='opacity-75
 hover:opacity-100'>
            Plugins
          </Link>
        </li>
        <li>
          <Contact
            className='opacity-75
 hover:opacity-100'
          />
        </li>
      </ul>
      <p className='text-xs text-center mt-5 opacity-50'>
        Copyright © StellarDev 2019. All rights reserved.
      </p>
    </footer>
  )
}
