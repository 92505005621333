/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react'
import Header from '../header/header'
import Footer from '../footer/footer'
import './layout.css'

if (typeof window !== 'undefined') {
  // eslint-disable-next-line global-require
  require('smooth-scroll')('a[href*="#"]')
}

const Layout = ({ children }) => {
  return (
    <div className='layoutContainer'>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}

export default Layout
