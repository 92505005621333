import React, { useState, useRef } from 'react'

import contactStyles from './contact.module.css'

import Button from '../button/button'

import anime from 'animejs/lib/anime.es.js'

export default function Contact ({ className }) {
  const [open, setOpen] = useState(false)

  const contactRef = useRef()

  const handleClick = () => {
    if (!open) {
      setOpen(true)

      contactRef.current.classList.add('flex')

      anime({
        targets: contactRef.current,
        duration: 1000,
        opacity: 1
      })
    }
  }

  const handleClose = () => {
    if (open) {
      setOpen(false)

      anime({
        targets: contactRef.current,
        duration: 1000,
        opacity: 0
      })
      contactRef.current.classList.remove('flex')
    }
  }

  return (
    <div>
      <button className={className} onClick={handleClick}>
        Contact
      </button>
      <div
        className={contactStyles.modal}
        ref={contactRef}
        onClick={() => handleClose()}>
        <div className={contactStyles.content}>
          <button className={contactStyles.close} onClick={handleClose}>
            &times;
          </button>
          <h2 className='text-center mb-3'>Contact Us</h2>
          <div className='flex flex-col justify-center items-center'>
            <Button
              type='int'
              loc='https://discord.gg/JSeTstH'
              btnType='secondary'
              className='mb-3'
              target>
              Join our <span className='font-bold'>Discord</span>
            </Button>
            <Button
              type='int'
              loc='https://twitter.com/stellardevorg'
              btnType='secondary'
              className='mb-3'
              target>
              Contact us on <span className='font-bold'>Twitter</span>
            </Button>

            <Button
              type='int'
              loc='mailto:support@stellardev.org'
              btnType='primary'>
              <span className='font-bold'>Email Us</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
